import {ContactForm, FullPhoto, Hero, Layout, Navigation, TextBox, WhatWeDid,} from "components/CaseLayout";
import SEO from "components/seo";
import React from "react";
import {useCaseData} from "shared/Hooks/CasesNew/useCaseData";
import {PogorzanskiTygielSmakow} from "shared/Types/cases";
import {ColumnPhoto} from '../../components/CaseLayout';
import {ContentPhoto} from "../../components/CaseLayout";

const PogorzanskiTygielSmakowPage = () => {
  const data = useCaseData(PogorzanskiTygielSmakow);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero data={data.hero} color={data.hero.color}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={PogorzanskiTygielSmakow}/>
      <ColumnPhoto data={data.column_images.first}/>
      <TextBox data={data.text_boxes.first}/>
      <FullPhoto data={data.full_images.first}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <FullPhoto data={data.full_images.fourth}/>
      <ContentPhoto data={data.content_images.first}/>
      <TextBox data={data.text_boxes.second}/>
      <ContentPhoto data={data.content_images.second}/>
      <FullPhoto data={data.full_images.fifth}/>
      <ContentPhoto data={data.content_images.third} content={547}/>
      <ContactForm/>
    </Layout>
  );
};

export default PogorzanskiTygielSmakowPage;
